import { useTheme } from "@emotion/react";
import { Dialog, DialogContent, DialogTitle, Grid, Grow, Typography } from "@mui/material"
import React from "react"
import { LuCircuitBoard, LuHelpCircle, LuMail, LuPaintBucket, LuPersonStanding, LuPlusCircle, LuTrash, LuTrophy } from "react-icons/lu";
import InlineIconTypography from "../common/InlineIconTypography";
import { AccentColoredButton } from "../../styles/Button";
import useWindowDimensions, { isMobile } from "../../hook/WindowDimensions";

const HowToPopup = (props) => {
    const theme = useTheme();

    const { height, width } = useWindowDimensions();

    const handleClose = () => {
        props.setVisible(false);
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            TransitionComponent={Grow}
            TransitionProps={{ timeout: 800 }}
            fullWidth>

            <DialogTitle style={{
                textAlign: 'left',
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                borderBottom: '1px solid ' + theme.palette.secondary.main
            }}>
                <InlineIconTypography
                    icon={<LuHelpCircle fontSize={22} />}
                    typography={<Typography variant="h5">
                        How to
                        <Typography variant="h5" display={'inline'} color={theme.palette.secondary.mainLight} marginLeft={theme.spacing(0.8)}>
                            Play
                        </Typography>
                    </Typography>}
                    spacing={1}
                />
            </DialogTitle>

            <DialogContent dividers style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
                <Grid container direction={'row'} spacing={isMobile(width) ? 1 : 1.5} paddingInline={0.5}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Connect films that share a criteria</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<LuPlusCircle fontSize={16} />}
                            typography={<Typography variant="body2" color={theme.palette.secondary.mainLight}>Each criteria has a level of difficulty: easy, medium, hard</Typography>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<LuCircuitBoard fontSize={16} />}
                            typography={<Typography variant="body2" color={theme.palette.secondary.mainLight}>Four movies act as fillers. They don't belong to any group.</Typography>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<LuTrash fontSize={16} />}
                            typography={<Typography variant="body2" color={theme.palette.secondary.mainLight}>You can get ride of the fillers by using the broom</Typography>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Examples of criterias</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<LuPaintBucket fontSize={16} />}
                            typography={<Typography variant="body2" color={theme.palette.secondary.mainLight}>Common themed plots</Typography>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<LuPersonStanding fontSize={16} />}
                            typography={<Typography variant="body2" color={theme.palette.secondary.mainLight}>Sharing the same actor, director or composer</Typography>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<LuTrophy fontSize={16} />}
                            typography={<Typography variant="body2" color={theme.palette.secondary.mainLight}>Oscar-winning pictures</Typography>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Feedback and Suggestions</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<LuMail fontSize={16} color={theme.palette.secondary.mainLight} />}
                            typography={<Typography variant="body2" color={theme.palette.secondary.mainLight}>Message us at filmconnect.puzzle@gmail.com</Typography>}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
};

export default HowToPopup;