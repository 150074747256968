import { useTheme } from "@emotion/react";
import { Button, Dialog, DialogContent, DialogTitle, Grid, Grow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react"
import InlineIconTypography from "../common/InlineIconTypography";
import { LuBarChart } from "react-icons/lu";
import { getDailyGameStatistics } from "../../service/DailyGameService";
import { PiNumberSquareFourLight, PiNumberSquareOneLight, PiNumberSquareThreeLight, PiNumberSquareTwoLight, PiNumberSquareZeroLight, PiXSquareLight } from "react-icons/pi";

import LinearProgressBar from "../common/LinearProgress";
import { toLongDate } from "../../util/DateUtils";
import InlineTypography from "../common/InlineTypography";
import useWindowDimensions, { isMobile } from "../../hook/WindowDimensions";

const ActiveGameStatisticsPopup = (props) => {
    const theme = useTheme();
    const { height, width } = useWindowDimensions();
    const [stats, setStats] = useState(null);


    useEffect(() => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        getDailyGameStatistics(timeZone).then((response) => {
            setStats(response.data.missingGuessDistribution);
        }).catch((error) => {
            setStats({
                "0": { total: 0, percentage: 0 },
                "1": { total: 0, percentage: 0 },
                "2": { total: 0, percentage: 0 },
                "3": { total: 0, percentage: 0 },
                "4": { total: 0, percentage: 0 },
                "5": { total: 0, percentage: 0 },
            })
        })
    }, [props.open])

    const getValue = (value) => { // this will be safeguarded on the BE soon
        return value ? value.total ? value.total : 0 : 0;
    }

    const getTotalCount = () => {
        return getValue(stats["0"]) + getValue(stats["1"]) + getValue(stats["2"]) + getValue(stats["3"]) + getValue(stats["4"]) + getValue(stats["5"]);
    }

    const handleClose = () => {
        props.setVisible(false);
    }

    return (
        stats &&
        <Dialog
            open={props.open}
            onClose={handleClose}
            TransitionComponent={Grow}
            TransitionProps={{ timeout: 800 }}
            fullWidth>

            <DialogTitle style={{
                textAlign: 'left',
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                borderBottom: '1px solid ' + theme.palette.secondary.main
            }}>
                <InlineIconTypography
                    icon={<LuBarChart fontSize={24} />}
                    typography={<Typography variant="h5">
                        Today
                        <Typography variant="h5" display={'inline'} color={theme.palette.secondary.mainLight} marginLeft={theme.spacing(0.8)}>
                            Statistics
                        </Typography>
                    </Typography>}
                    spacing={1}
                />
            </DialogTitle>

            <DialogContent dividers style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
                <Grid container direction={'row'} spacing={isMobile(width) ? 1 : 1.5} paddingInline={0.5}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Missed Guesses Distribution for {toLongDate(new Date())}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InlineTypography
                            spacing={0.5}
                            typography={<Typography variant="body2" color={theme.palette.secondary.mainLight}> Submitted by a total of</Typography>}
                            typographyAppend={
                                <InlineTypography
                                    spacing={0.5}
                                    typography={<Typography variant="body2" display="inline" style={{ backgroundColor: theme.palette.primary.contrastText, paddingInline: theme.spacing(0.3), borderRadius: theme.spacing(0.4), color: theme.palette.primary.main, fontWeight: 'bold' }}>
                                        {getTotalCount()}
                                    </Typography>}
                                    typographyAppend={
                                        <Typography variant="body2" color={theme.palette.secondary.mainLight}>users</Typography>
                                    }
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<PiNumberSquareZeroLight fontSize={20} color={theme.palette.secondary.mainLight} />}
                            typography={<LinearProgressBar currentCount={getValue(stats["0"])} totalCount={getTotalCount()} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<PiNumberSquareOneLight fontSize={20} color={theme.palette.secondary.mainLight} />}
                            typography={<LinearProgressBar currentCount={getValue(stats["1"])} totalCount={getTotalCount()} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<PiNumberSquareTwoLight fontSize={20} color={theme.palette.secondary.mainLight} />}
                            typography={<LinearProgressBar currentCount={getValue(stats["2"])} totalCount={getTotalCount()} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<PiNumberSquareThreeLight fontSize={20} color={theme.palette.secondary.mainLight} />}
                            typography={<LinearProgressBar currentCount={getValue(stats["3"])} totalCount={getTotalCount()} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<PiNumberSquareFourLight fontSize={20} color={theme.palette.secondary.mainLight} />}
                            typography={<LinearProgressBar currentCount={getValue(stats["4"])} totalCount={getTotalCount()} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<PiXSquareLight fontSize={20} color="red" />}
                            typography={<LinearProgressBar color={"red"} currentCount={getValue(stats["5"])} totalCount={getTotalCount()} />}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
};

export default ActiveGameStatisticsPopup;