export const BASE_PATH = "https://api.filmconnect.fun/api";
export const DAILY_GAME_SLUG = "/daily";

export const DIFFICULTY_COLOR_MAP = new Map([
    ['EASY', 'green'],
    ['MEDIUM', '#BDB76B'],
    ['HARD', 'red'],
    ['EXTREME', 'darkred']
]);

export const DIFFICULTY_EMOJI_MAP = new Map([
    ['EASY', '🟢'],
    ['MEDIUM', '🟡'],
    ['HARD', '🔴'],
    ['EXTREME', '🔴🔴🔴']
]);
