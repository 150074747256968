import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react"
import InlineIconTypography from "./common/InlineIconTypography";
import { LuCalendar } from "react-icons/lu";
import { toLongDate } from "../util/DateUtils";

const DateHeader = (props) => {
    const theme = useTheme();

    const date = new Date();

    return (
        <Box style={{ padding: theme.spacing(1), border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px', backgroundColor: theme.palette.secondary.main }} color={theme.palette.primary.contrastText}>
            <InlineIconTypography
                icon={<LuCalendar fontSize={14}/>}
                typography={
                    <Typography variant="body2" display={'inline'} marginLeft={theme.spacing(0.8)} color={theme.palette.secondary.mainLight}>
                        {toLongDate(date)}
                    </Typography>}
                spacing={theme.spacing(1)}
            />
        </Box>
    )
};

export default DateHeader;
