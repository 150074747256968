import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { Button, Chip, Grid, IconButton, Typography } from "@mui/material";
import { getDailyGame, getGameState, getPuzzleInfo, getStats, postDailyGameEntry, saveGameState, savePuzzleInfo, saveStats } from "../service/DailyGameService";
import { BASE_GAME_STATE, BASE_STATS } from "../util/PuzzleUtils";
import { getCurrentDate, toLongDate } from "../util/DateUtils";
import { findMatchingMovieSet, flattenMovies, shuffleArray, sortMoviesByDifficulty, sortRowAnswerByDifficulty } from "../util/PuzzleParseUtil";
import toast from "react-hot-toast";
import { DIFFICULTY_EMOJI_MAP } from "../config/RestConfig";
import ActiveGameStatisticsPopup from "./popup/ActiveGameStatisticsPopup";
import useWindowDimensions, { isMobile } from "../hook/WindowDimensions";
import InlineIconTypography from "./common/InlineIconTypography";
import InlineTypography from "./common/InlineTypography";
import { LuCheckCircle } from "react-icons/lu";
import { LiaBroomSolid } from "react-icons/lia";
import MovieCard from "./MovieCard";
import { AccentColoredButton, LighBorderAccentColoredButton, LightAccentColoredButton } from "../styles/Button";

const MoviesBlock = (props) => {
    const theme = useTheme();
    const { width } = useWindowDimensions();

    const MOVIES_SIZE = 16;
    const GUESSES = 5;
    const MOVIE_GROUP_SIZE = 4;
    const FILLER = 'FILLER';

    const [gameStatisticsPopup, setGameStatisticsPopup] = useState(false);

    const [guesses, setGuesses] = useState(GUESSES);
    const [movies, setMovies] = useState([]);
    const [clicked, setClicked] = useState(Array(MOVIES_SIZE).fill(false));
    const [transition, setTransition] = useState(Array(MOVIES_SIZE).fill({ css: '', value: false }));
    const [locked, setLocked] = useState([]);
    const [rowAnswer, setRowAnswer] = useState([]);
    const [won, setWon] = useState({ state: false, quit: false });

    useEffect(() => {
        const dailyPuzzle = getPuzzleInfo();
        if (dailyPuzzle === null) { // (1) completely fresh player, new game & new state
            fetchNewGame();
            updateGameState(BASE_GAME_STATE);
            saveStats(BASE_STATS);
        } else {
            const currentDate = getCurrentDate();

            if (dailyPuzzle.gameDate === currentDate) { // (2) already fetched current puzzle, get game state
                const state = getGameState();
                if (state === null) { // if for some reason there's no state
                    updateGameState(BASE_GAME_STATE);
                } else {
                    updateGameState(state);
                }
                updateMoviesByGameState(dailyPuzzle, state);
            } else { // (3) need to fetch new puzzle and new state - date has changed
                fetchNewGame();
                updateGameState(BASE_GAME_STATE);
            }
        }
    }, [])

    // ----- UPDATING GAME ENGINE ----- //

    const fetchNewGame = () => { // fetch daily game from server and update storage
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        getDailyGame(timeZone || null).then((response) => {
            const newGame = response.data;
            savePuzzleInfo(newGame);
            const movies = flattenMovies(newGame);
            setMovies(shuffleArray(movies.slice(0, MOVIES_SIZE)));
        }).catch((error) => {
            toast.error('Failure while loading the puzzle. Please try again later')
        })
    }

    const updateGameState = (state) => { // update storage with state
        saveGameState(state)

        setRowAnswer(state.rowAnswer);
        setLocked(state.locked);
        setGuesses(state.guessesRemaining);
        setWon(state.won);
    }

    const updateMoviesByGameState = (game) => {
        const state = getGameState();
        updateMoviesByRowAnswer(state.rowAnswer, game);
    }

    const updateMoviesByRowAnswer = (rowAnswer, game) => {
        const movieArray = [];
        const movieShuffle = [];

        const hasUpdatedMoviePayload = hasUpdatedGamePayload(); // TODO: THINK WE CAN DELETE THIS; BUT I'LL LEAVE FOR NOW.

        rowAnswer
            .forEach(element => {
                if (!hasUpdatedMoviePayload && element.difficulty === FILLER) {
                    return;
                }
                const moviesByDifficulty = game.moviesConnections[element.difficulty].movies
                if (element.value) {
                    moviesByDifficulty.forEach(element => {
                        movieArray.push(element);
                    })
                } else {
                    moviesByDifficulty.forEach(element => {
                        movieShuffle.push(element);
                    })
                }
            });
        const shuffled = shuffleArray(movieShuffle);
        setMovies(movieArray.concat(shuffled));
    }

    const hasUpdatedGamePayload = () => {
        const game = getPuzzleInfo();
        return game.moviesConnections[FILLER] !== undefined;
    }

    // ----- HANDLING ACTIONS ----- //

    const handleMovieBoxClick = (index) => {
        const trueCount = clicked.filter(value => value).length;
        const isAlreadyClicked = clicked[index];

        const updatedClicked = [...clicked];
        if (isAlreadyClicked) {
            updatedClicked[index] = false;
            setClicked(updatedClicked);
        } else {
            if (trueCount < MOVIE_GROUP_SIZE) {
                updatedClicked[index] = true;
                setClicked(updatedClicked);

                //animation
                const updatedTransition = Array(MOVIES_SIZE).fill(false);
                updatedTransition[index] = {
                    css: 'movie-card-mini-transition',
                    value: true
                };
                setTransition(updatedTransition);
                setTimeout(() => {
                    setTransition(Array(MOVIES_SIZE).fill(false));
                }, 400);
            } else {
                toast.error('You already selected four movies');
            }
        }
    };

    const handleGuess = () => {
        const selectedIndexes = clicked.reduce((acc, value, index) => { // get selected indexes clicked[true]
            if (value) {
                acc.push(index);
            }
            return acc;
        }, []);

        if (selectedIndexes.length === MOVIE_GROUP_SIZE) {

            const game = getPuzzleInfo();
            const sortedMovies = selectedIndexes.map(index => movies[index]);
            const matchingResult = findMatchingMovieSet(sortedMovies, game);

            if (matchingResult.difficulty !== null && matchingResult.difficulty !== FILLER) { // (1) correct guess (ignoring FILLER), update {locked, rowAnswer, guesses} 
                handleCorrectGuess(selectedIndexes, matchingResult.difficulty, game);
            } else {
                if (matchingResult.counter === (MOVIE_GROUP_SIZE - 1)) {
                    toast("One away! 🔎");
                }
                handleWrongGuess(selectedIndexes);
            }

        } else {
            toast.error('You must select exactly four movies');
        }
    };

    const handleCorrectGuess = (selectedIndexes, difficulty, game) => {
        const updatedTransition = Array(MOVIES_SIZE).fill(false);
        selectedIndexes.forEach(index => {
            updatedTransition[index] = {
                css: 'movie-card-transition',
                value: true
            };
        });

        var updatedLocked = [...locked];
        var updateRow = [];

        // replace rowAnswer difficulty with true (can't just replace it with true because I want to show it first)
        updateRow.push({
            value: true,
            difficulty: difficulty
        });

        for (let i = 0; i < rowAnswer.length; i++) {
            const row = rowAnswer[i];
            if (row.difficulty !== difficulty) {
                updateRow.push(row)
            }
        }

        for (let i = 0; i < updateRow.length; i++) {
            if (updateRow[i].value) {
                for (let j = 0; j < MOVIE_GROUP_SIZE; j++) {
                    updatedLocked[i * MOVIE_GROUP_SIZE + j] = true;
                }
            }
        }

        setTransition(updatedTransition);
        setTimeout(() => {
            setClicked(Array(MOVIES_SIZE).fill(false));
            setTransition(Array(MOVIES_SIZE).fill(false));
            const sortedMovies = selectedIndexes.map(index => movies[index]);
            setMovies(sortedMovies.concat(movies.filter((_, index) => !selectedIndexes.includes(index))));

            const hasWon = (hasGuessedFillers() && updateRow[3].value) || (!hasGuessedFillers() && updateRow[2].value);

            var updatedRowCopy = [...updateRow]; // Copy updateRow to avoid mutation issues

            if (hasWon) { // win condition
                setWon({ state: true, quit: false });

                const stats = getStats();
                stats.played.push(getCurrentDate());
                stats.stats[GUESSES - guesses]++;
                saveStats(stats);

                postDailyGameEntry({
                    gameId: game.id,
                    playerUuid: stats.uuid,
                    hasQuit: false,
                    attemptsRemaining: guesses
                });

                updatedRowCopy = sortRowAnswerByDifficulty(updatedRowCopy);
                const sortedMoviesByDifficulty = sortMoviesByDifficulty(getPuzzleInfo());
                setMovies(sortedMoviesByDifficulty);
                updatedLocked = Array(MOVIES_SIZE).fill(true); // LOCK EVERYTHING IF WON (include fillers)
            }
            updateGameState(
                {
                    guessesRemaining: guesses,
                    rowAnswer: updatedRowCopy,
                    locked: updatedLocked,
                    won: { state: hasWon, quit: false }
                }
            );
        }, 500);
    }

    const handleWrongGuess = (selectedIndexes) => {
        const updatedTransition = Array(MOVIES_SIZE).fill(false);
        selectedIndexes.forEach(index => {
            updatedTransition[index] = {
                css: 'movie-card-shake',
                value: true
            };
        });

        setTransition(updatedTransition);
        setTimeout(() => {
            setClicked(Array(MOVIES_SIZE).fill(false));
            setTransition(Array(MOVIES_SIZE).fill(false));

            updateGameState(
                {
                    guessesRemaining: guesses - 1,
                    rowAnswer: rowAnswer,
                    locked: locked,
                    won: { state: false, quit: false }
                }
            );
            if (guesses - 1 === 0) {
                setTimeout(() => {
                    handleGiveUp();
                }, 800)
            }
        }, 500);
    }

    const handleGiveUp = () => {
        setClicked(Array(MOVIES_SIZE).fill(true));
        setTransition(Array(MOVIES_SIZE).fill({
            css: 'movie-card-transition',
            value: true
        }));

        setTimeout(() => {
            const game = getPuzzleInfo();
            setClicked(Array(MOVIES_SIZE).fill(false));
            setTransition(Array(MOVIES_SIZE).fill(false));

            const stats = getStats();
            stats.played.push(getCurrentDate());
            stats.stats[-1]++;
            saveStats(stats);

            updateGameState(
                {
                    guessesRemaining: 0,
                    rowAnswer: [{ value: true, difficulty: 'EASY' }, { value: true, difficulty: 'MEDIUM' }, { value: true, difficulty: 'HARD' }, { value: true, difficulty: 'FILLER' }],
                    locked: Array(MOVIES_SIZE).fill(true),
                    won: { state: true, quit: true }
                }
            );
            updateMoviesByGameState(game);
            setWon({ state: true, quit: true });

            postDailyGameEntry({
                gameId: game.id,
                playerUuid: stats.uuid,
                hasQuit: true,
                attemptsRemaining: 0
            });
        }, 500);
    }

    const handleGameStatisticsClick = () => {
        setGameStatisticsPopup(true);
    }

    const handleFillersCleaning = () => {
        const game = getPuzzleInfo();

        const fillersName = game.moviesConnections[FILLER].movies.map(movie => movie.name);
        const fillersIndexes = movies.map((movie, index) => {
            if (fillersName.includes(movie.name)) {
                return index;
            }
        }).filter(index => index !== undefined);
        const updatedClicked = [...clicked];
        fillersIndexes.forEach(element => {
            updatedClicked[element] = true;
        })
        setClicked(updatedClicked);

        handleCorrectGuess(fillersIndexes, FILLER, game);
    }

    const handleShareStatisticsClick = () => {
        if (won.state && !won.quit) {
            const longDate = toLongDate(new Date())
            let statsText = `🎥 Figured https://www.filmconnect.fun ${longDate} Puzzle\n`;
            statsText += `🔍 Still got ${guesses} attempts to spare!\n\n`;
            statsText += '📊 Guess Order: \n'

            const reverseRowAnswer = rowAnswer.reverse();
            for (let i = 0; i < rowAnswer.length; i++) {
                if (reverseRowAnswer[i].difficulty !== FILLER) {
                    const value = DIFFICULTY_EMOJI_MAP.get(reverseRowAnswer[i].difficulty)
                    statsText += `${value} \n`;
                }
            }

            navigator.clipboard.writeText(statsText)
                .then(() => {
                    toast.success('Copied to clipboard')
                })
                .catch(err => {
                    toast.error('Could not generate stats. Try again later')
                });
        }
    }

    // ----- PROPERTY CHECKS ----- //

    const hasGuessedFillers = () => {
        const state = getGameState();
        return state.rowAnswer.some(item => item.difficulty === FILLER && item.value === true);
    }

    function hasFillers() {
        const state = getGameState();

        const hasFillers = hasGuessedFillers();
        const firstGuessIsDone = state.rowAnswer.some(item => item.value === true);
        const hasUpdatedMoviePayload = hasUpdatedGamePayload(); // TODO: THINK WE CAN DELETE THIS; BUT I'LL LEAVE FOR NOW.

        return hasFillers || firstGuessIsDone || !hasUpdatedMoviePayload;
    }

    return (
        <>
            <Grid container direction="row" spacing={theme.spacing(1.2)} paddingInline={theme.spacing(1.5)}>
                {movies.slice(0, MOVIES_SIZE).map((element, index) => {
                    const movieCard = <Grid item xs={3} key={index}>
                        <MovieCard
                            movie={element}
                            isClicked={clicked[index]}
                            onClick={handleMovieBoxClick}
                            index={index}
                            transition={transition[index]}
                            isLocked={locked[index]}
                        />
                    </Grid>;

                    //add answer if required (hammer way hahah)
                    if (index === 3 || index === 7 || index === 11) {
                        const rowAnswerIndex = index === 3 ? 0 : Math.floor(index / 4); // Calculate row answer index
                        const rowAnswerValue = rowAnswer[rowAnswerIndex];
                        if (true) {
                            return (
                                <>
                                    {movieCard}
                                    <Grid item xs={12} display={'flex'} justifyContent={'center'} textAlign={'center'}>
                                        <InlineTypography
                                            typography={
                                                <InlineIconTypography
                                                    icon={<></>}
                                                    typography={
                                                        <Typography variant="body2"
                                                            style={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.primary.contrastText, paddingInline: theme.spacing(0.8), paddingBlock: isMobile(width) ? theme.spacing(0.3) : theme.spacing(0.7), border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px' }}>
                                                            {rowAnswerValue.value ? getPuzzleInfo().moviesConnections[rowAnswerValue.difficulty].criteria : ''}
                                                        </Typography>
                                                    }
                                                />
                                            }
                                            typographyAppend={
                                                rowAnswerValue.value ?
                                                    <Chip label={rowAnswerValue.difficulty} style={{
                                                        color: theme.palette.primary.main,
                                                        fontWeight: '500',
                                                        fontSize: isMobile(width) ? 11 : '',
                                                        height: '2.5dvh',
                                                        backgroundColor: theme.palette.accent.main
                                                    }}></Chip>
                                                    : <></>
                                            }
                                        />

                                    </Grid>
                                </>
                            );
                        } else {
                            return movieCard;
                        }

                    } else
                        return movieCard;
                })}
                {
                    movies.length !== 0 &&
                    (
                        won.state ?
                            <>
                                {
                                    won.quit ?
                                        <>
                                            <Grid item xs={12} textAlign={'center'} marginTop={theme.spacing(1.5)}>
                                                <Typography variant="body2" color={theme.palette.primary.contrastText} fontWeight={'bold'}>
                                                    Boo Hoo! <Typography variant="body2" color={theme.palette.primary.contrastText} display={'inline'}> Better luck next time</Typography>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} textAlign={'center'} display={'flex'} justifyContent={'center'} >
                                                <LighBorderAccentColoredButton variant="outlined" onClick={handleGameStatisticsClick}>STATS</LighBorderAccentColoredButton>
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid item xs={12} textAlign={'center'} marginTop={theme.spacing(1.5)}>
                                                <Typography variant="body2" color={theme.palette.primary.contrastText}>
                                                    Congratulations! <Typography variant="body2" color={theme.palette.primary.contrastText} fontWeight={'600'} display={'inline'}> You figured the puzzle with {guesses} {guesses === 1 ? 'attempt' : 'attempts'} remaining!</Typography>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} display={'flex'} justifyContent={'center'} >
                                                <AccentColoredButton variant="outlined" onClick={handleShareStatisticsClick}>SHARE</AccentColoredButton>
                                                <LighBorderAccentColoredButton variant="outlined" style={{ backgroundColor: theme.palette.secondary.main, marginLeft: theme.spacing(4) }} onClick={handleGameStatisticsClick}>STATS</LighBorderAccentColoredButton>
                                            </Grid>
                                        </>
                                }
                            </>
                            :
                            <>
                                {
                                    guesses === 0 ?
                                        <>
                                            <Grid item xs={12} textAlign={'center'} marginTop={theme.spacing(1.5)}>
                                                <Typography variant="body2" color={theme.palette.primary.contrastText} fontWeight={'bold'}>
                                                    Boo Hoo! <Typography variant="body2" color={theme.palette.primary.contrastText} display={'inline'}> Better luck tomorrow</Typography>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} display={'flex'} justifyContent={'center'} >
                                                <LighBorderAccentColoredButton variant="outlined" style={{ backgroundColor: theme.palette.secondary.main }} onClick={handleGameStatisticsClick}>STATS</LighBorderAccentColoredButton>
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid item xs={12} textAlign={'center'} marginTop={theme.spacing(1.5)}>
                                                <Typography variant="body2" color={theme.palette.primary.contrastText}>
                                                    You have <Typography variant="body2" display="inline" style={{ backgroundColor: theme.palette.primary.contrastText, paddingInline: theme.spacing(0.3), borderRadius: theme.spacing(0.4), color: theme.palette.primary.main, fontWeight: 'bold' }}>{guesses}</Typography> <Typography variant="body2" display="inline"> attempts remaining</Typography>
                                                    {
                                                        <IconButton disabled={hasFillers()} variant="outlined" style={{ backgroundColor: hasFillers() ? 'gray' : theme.palette.secondary.mainLight, marginLeft: theme.spacing(1), padding: theme.spacing(0.5) }} onClick={handleFillersCleaning}>
                                                            <LiaBroomSolid fontSize={18} style={{ color: theme.palette.secondary.main }} />
                                                        </IconButton>
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} display={'flex'} justifyContent={'center'} marginTop={theme.spacing(1)}>
                                                <AccentColoredButton variant="outlined" onClick={handleGuess}>GUESS</AccentColoredButton>
                                                <LighBorderAccentColoredButton variant="outlined" style={{ marginLeft: theme.spacing(4) }} onClick={handleGiveUp}>GIVE UP</LighBorderAccentColoredButton>
                                            </Grid>
                                        </>
                                }
                            </>
                    )
                }
            </Grid>
            <ActiveGameStatisticsPopup open={gameStatisticsPopup} setVisible={setGameStatisticsPopup} />
        </>
    );
}

export default MoviesBlock;