import logo from './logo.svg';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material';
import { ACCENT_COLOR, LIGHT_TEXT_COLOR, PRIMARY_COLOR, PRIMARY_TEXT_COLOR, SECONDARY_COLOR, SECONDARY_TEXT_COLOR } from './config/ColorConfig';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainPage from './page/MainPage';
import useWindowDimensions, { isMobile } from './hook/WindowDimensions';
import { Analytics } from '@vercel/analytics/react';

function App() {

  const { height, width } = useWindowDimensions();

  // theme coloring
  const theme = createTheme({
    palette: {
      primary: {
        main: PRIMARY_COLOR,
        mainLight: 'gray',
        dark: PRIMARY_COLOR,
        contrastText: PRIMARY_TEXT_COLOR,
        contrastTextLight: PRIMARY_TEXT_COLOR,
        success: '#4B6538',
        failure: 'red'
      },
      secondary: {
        main: SECONDARY_COLOR,
        mainLight: LIGHT_TEXT_COLOR,
        dark: SECONDARY_COLOR,
        contrastText: SECONDARY_TEXT_COLOR,
        contrastTextDark: SECONDARY_TEXT_COLOR
      },
      accent: {
        main: ACCENT_COLOR,
        dark: ACCENT_COLOR,
        contrastText: PRIMARY_COLOR
      },
    },
    typography: {
      subtitle3: {
        fontSize: 12,
        fontFamily: 'Inter, sans-serif'
      }
    }
  });

  theme.typography.h1.fontFamily = 'Montserrat, sans-serif';
  theme.typography.h1.fontWeight = 'bold';
  theme.typography.h2.fontFamily = 'Montserrat, sans-serif';
  theme.typography.h2.fontWeight = 'bold';
  theme.typography.h3.fontFamily = 'Montserrat, sans-serif';
  theme.typography.h3.fontWeight = 'bold';
  theme.typography.h4.fontFamily = 'Montserrat, sans-serif';
  theme.typography.h4.fontWeight = 'bold';
  theme.typography.h4.fontSize = 36;
  theme.typography.h5.fontFamily = 'Montserrat, sans-serif';
  theme.typography.h5.fontWeight = 'bold';
  theme.typography.h5.fontSize = isMobile(width) ? 22 : 24;
  theme.typography.h6.fontFamily = 'Montserrat, sans-serif';
  theme.typography.h6.fontWeight = 'bold';
  theme.typography.h6.fontSize = isMobile(width) ? 13.5 : 15;

  theme.typography.body1.fontFamily = 'Inter, sans-serif';
  theme.typography.body1.fontSize = isMobile(width) ? 14 : 16;
  theme.typography.body2.fontFamily = 'Inter, sans-serif';
  theme.typography.body2.fontSize = isMobile(width) ? 12.5 : 14;

  theme.typography.subtitle1.fontFamily = 'Inter, sans-serif';
  theme.typography.subtitle2.fontFamily = 'Inter, sans-serif';

  theme.typography.button.fontFamily = 'Inter, sans-serif';
  theme.typography.button.fontSize = 16;

  return (
    <ThemeProvider theme={theme}>
      <Toaster
        position={'bottom-center'}
        toastOptions={{
          duration: 1200,
          style: {
            padding: theme.spacing(0.5), border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px', backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
          }
        }}
      />
      <Analytics mode='production' />      
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<MainPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
