import { useTheme } from '@emotion/react';
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";

import React, { useState } from 'react';
import { LuBarChart, LuHelpCircle } from 'react-icons/lu';
import HowToPopup from './popup/HowToPopup';
import { getStats } from '../service/DailyGameService';
import StatsPopup from './popup/StatsPopup';

const TopBar = (props) => {
    const theme = useTheme();

    const [help, setHelp] = useState(false);
    const [stats, setStats] = useState(false);


    const handleHelpClick = () => {
        setHelp(true);
    }

    const handleStatsClick = () => {
        setStats(true);
    }

    return (
        <>
            <AppBar
                position="static"
                style={{ flexGrow: 1, borderBottom: '1px solid ' + theme.palette.secondary.main, backgroundColor: theme.palette.secondary.main }}>
                <Toolbar>
                    <Typography variant="h5" color={theme.palette.primary.contrastText} fontWeight='bold' flexGrow={1}>
                        film connect
                    </Typography>
                    <IconButton onClick={handleHelpClick}>
                        <LuHelpCircle color='white' />
                    </IconButton>
                    <IconButton onClick={handleStatsClick}>
                        <LuBarChart color='white' />
                    </IconButton>
                </Toolbar>
            </AppBar >
            <HowToPopup open={help} setVisible={setHelp} />
            {getStats() !== null ? <StatsPopup open={stats} setVisible={setStats} /> : <></>}
        </>

    );
}

export default TopBar;
