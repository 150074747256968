import styled from "@emotion/styled";
import { Button } from "@mui/material";
export const AccentColoredButton = styled(Button)(({ theme }) => ({
    fontSize: 15,
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.accent.contrastText,
    border: '1px solid ' + theme.palette.accent.main,
    borderRadius: theme.spacing(5),
    '&:hover': {
        backgroundColor: theme.palette.secondary.mainLight,
    },
    height: '4dvh',
    paddingInline: theme.spacing(2)
}));

export const LightAccentColoredButton = styled(Button)(({ theme }) => ({
    fontSize: 13,
    color: theme.palette.primary.contrastText,
    '&:hover': {
        backgroundColor: theme.palette.secondary.main,
    },
    borderRadius: theme.spacing(5),
    borderRadius: '10%',
    height: '4dvh',
    paddingInline: theme.spacing(2)
}));

export const LighBorderAccentColoredButton = styled(Button)(({ theme, width }) => ({
    fontSize: 15,
    color: theme.palette.primary.contrastText,
    border: '1px solid ' + theme.palette.primary.contrastText,
    borderRadius: theme.spacing(5),
    "&:disabled": {
        color: theme.palette.primary.contrastText,
        borderBottom: '1px solid ' + theme.palette.primary.contrastText,
    },
    '&:hover': {
        backgroundColor: theme.palette.secondary.mainLight,
        color: theme.palette.primary.contrastText,
    },
    height: '4dvh',
    paddingInline: theme.spacing(2)
}));