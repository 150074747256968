import { v4 as uuidv4 } from 'uuid';


export const BASE_POPUP_ACKNOWLEDGEMENT = false;

export const BASE_GAME_STATE = {
    "guessesRemaining": 5,
    "rowAnswer": [{ value: false, difficulty: 'EASY' }, { value: false, difficulty: 'MEDIUM' }, { value: false, difficulty: 'HARD' }, { value: false, difficulty: 'FILLER' }],
    "locked": [false, false, false, false, false, false, false, false, false, false, false, false],
    "won": { state: false, quit: false }
}

export const BASE_STATS = {
    "uuid": uuidv4().toString(),
    "played": [],
    "stats": { // -1 represents failure
        "-1": 0,
        "0": 0,
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
        "5": 0
    }
}