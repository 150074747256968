export function getYearFromString(date) {
    return date.split("-")[2];
}

export function getCurrentDate() {
    const date = new Date();
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is zero-based
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
}

export function toLongDate(date) {
    return date.toLocaleDateString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
}