import { useTheme } from "@emotion/react";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { getYearFromString } from "../util/DateUtils";
import useWindowDimensions, { isMobile } from "../hook/WindowDimensions";
import { LuInfo } from "react-icons/lu";
import { useLongPress } from 'use-long-press';
import MovieDetailsPopup from "./popup/MovieDetailsPopup";


const MovieCard = (props) => {
    const theme = useTheme();

    const [isHovered, setIsHovered] = useState(false);
    const [movieDetailsPopup, setMovieDetailsPopup] = useState(false);

    const bind = useLongPress(() => {
        setMovieDetailsPopup(true);
    });

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleClick = () => {
        if (!movieDetailsPopup) {
            props.onClick(props.index);
        }
    };

    const handleInfoClick = (event) => {
        event.stopPropagation(); // stop clicking the box as a whole
        setMovieDetailsPopup(true);
    };

    const { width } = useWindowDimensions();
    const mobileWidth = isMobile(width);

    return (
        <Box
            {...bind()}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={props.isLocked ? null : handleClick}
            className={props.isClicked && props.transition.value ? props.transition.css : ''}
            style={{
                backgroundColor: props.isClicked || props.isLocked ? '#b5b5b5' : 'white',
                cursor: props.isLocked ? '' : 'pointer',
                color: props.isClicked ? 'white' : 'inherit',
                height: isMobile(width) ? '14dvh' : '12dvh',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                position: 'relative',
                border: isHovered || props.isClicked || props.isLocked ? '1px solid gray' : '1px solid rgba(0, 0, 0, 0.5)',
                paddingInline: 5
            }}
        >
            <Typography
                variant="body2"
                fontWeight={'500'}
                style={{
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal'
                }}>
                {props.movie.name}
                <Typography component="span" display={'inline'} fontSize={11}> ({getYearFromString(props.movie.publishedAt)}) </Typography>
            </Typography>
            {
                (mobileWidth === true) ? <></> : <IconButton
                    style={{
                        position: 'absolute',
                        top: -3,
                        right: 0
                    }}
                    aria-label="info"
                    onClick={handleInfoClick}
                >
                    <LuInfo fontSize={20} color={(props.isLocked || props.isClicked) ? theme.palette.primary.contrastText : ''} />
                </IconButton>
            }
            <MovieDetailsPopup open={movieDetailsPopup} setVisible={setMovieDetailsPopup} movie={props.movie} />
        </Box>
    );
};

export default MovieCard;
