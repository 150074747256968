import { useTheme } from "@emotion/react";
import { Button, Dialog, DialogContent, DialogTitle, Grid, Grow, Typography } from "@mui/material"
import React from "react"
import { LuPlusCircle } from "react-icons/lu";
import InlineIconTypography from "../common/InlineIconTypography";
import { getYearFromString } from "../../util/DateUtils";
import { AccentColoredButton, LighBorderAccentColoredButton } from "../../styles/Button";
import useWindowDimensions, { isMobile } from "../../hook/WindowDimensions";

const MovieDetailsPopup = (props) => {
    const theme = useTheme();
    const { height, width } = useWindowDimensions();


    const handleClick = (event) => {
        handleClose();
    }

    const handleClose = () => {
        props.setVisible(false);
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            TransitionComponent={Grow}
            TransitionProps={{ timeout: 800 }}
            fullWidth>

            <DialogTitle style={{
                textAlign: 'left',
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                borderBottom: '1px solid ' + theme.palette.secondary.main
            }}>
                <InlineIconTypography
                    icon={<LuPlusCircle fontSize={20} />}
                    typography={<Typography variant="h5">
                        Film
                        <Typography variant="h5" display={'inline'} color={theme.palette.secondary.mainLight} marginLeft={theme.spacing(0.7)}>
                            Details
                        </Typography>
                    </Typography>}
                    spacing={1}
                />
            </DialogTitle>

            <DialogContent dividers style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
                <Grid container direction={'row'} spacing={isMobile(width) ? 1 : 1.5} paddingInline={0.5}>
                    <Grid item xs={12}>
                        <Typography variant="h6"> Name </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<Typography>·</Typography>}
                            typography={<Typography variant="body2" color={theme.palette.secondary.mainLight}>{props.movie.name} ({getYearFromString(props.movie.publishedAt)})</Typography>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6"> Genre </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<Typography>·</Typography>}
                            typography={<Typography variant="body2" color={theme.palette.secondary.mainLight}> {props.movie.genre || 'No extra information available'}</Typography>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6"> Main Cast </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<Typography>·</Typography>}
                            typography={<Typography variant="body2" color={theme.palette.secondary.mainLight}>{props.movie.cast || 'No extra information available'} </Typography>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6"> Plot Information </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<Typography>·</Typography>}
                            typography={<Typography variant="body2" color={theme.palette.secondary.mainLight}>{props.movie.plot || 'No extra information available'} </Typography>}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
};

export default MovieDetailsPopup;