import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react"
import useWindowDimensions, { isMobile } from "../hook/WindowDimensions";

const TipsBlock = (props) => {
  const theme = useTheme();

  const { width } = useWindowDimensions();
  const mobileWidth = isMobile(width);

  return (
    <Box>
      {
        mobileWidth ?
          <>
          <Typography variant="body2" color={theme.palette.primary.contrastText}>
          Hold the
              <Typography variant="body2" fontWeight={'bold'} display={'inline'} marginLeft={theme.spacing(0.4)} marginRight={theme.spacing(0.4)}>
                movie card
              </Typography>
              for more details
            </Typography>
          </>
          :
          <Typography variant="body2" color={theme.palette.primary.contrastText}>
            Submit
            <Typography variant="body2" fontWeight={'bold'} display={'inline'} marginLeft={theme.spacing(0.4)} marginRight={theme.spacing(0.4)}>
              three
            </Typography>
            groups of four
          </Typography>
      }

    </Box>
  )
};

export default TipsBlock;
