import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const PrimaryDarkBackgroundPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
}));


export const SecondaryDarkBackgroundPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.mainLight,
    border: '1px solid ' + theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
        border: '1px solid ' + theme.palette.primary.contrastText,
        cursor: 'pointer'
    }
}));