import { Stack } from "@mui/material";


const InlineTypography = (props) => {

    return (
        <Stack direction={'row'} spacing={props.spacing ? props.spacing : 1} alignItems={'center'}>
            {props.typography}
            {props.typographyAppend}
        </Stack>
    );
};

export default InlineTypography;