import { Stack } from "@mui/material";

const InlineIconTypography = (props) => {

    return (
        <Stack direction={'row'} spacing={props.spacing ? props.spacing : 1} alignItems={'center'} >
            {
                props.alignment === 'right' ?
                    <>
                        {props.typography}
                        {props.icon}
                    </>
                    :
                    <>
                        {props.icon}
                        {props.typography}
                    </>
            }
        </Stack>
    );
};

export default InlineIconTypography;