import { useTheme } from "@emotion/react";
import { Button, Dialog, DialogContent, DialogTitle, Grid, Grow, Typography } from "@mui/material";
import React from "react"
import InlineIconTypography from "../common/InlineIconTypography";
import { LuBarChart, LuCheck } from "react-icons/lu";
import { getStats as getStatistics } from "../../service/DailyGameService";
import { PiNumberSquareFourLight, PiNumberSquareOneLight, PiNumberSquareThreeLight, PiNumberSquareTwoLight, PiNumberSquareZeroLight, PiXSquareLight } from "react-icons/pi";

import InlineTypography from "../common/InlineTypography";
import LinearProgressBar from "../common/LinearProgress";
import toast from "react-hot-toast";
import { AccentColoredButton, LighBorderAccentColoredButton, LightAccentColoredButton } from "../../styles/Button";
import useWindowDimensions, { isMobile } from "../../hook/WindowDimensions";

const StatsPopup = (props) => {
    const theme = useTheme();

    const { height, width } = useWindowDimensions();

    const getStats = () => {
        const stats = getStatistics();
        const statsMap = stats.stats;
        const totalGames = Object.values(statsMap).reduce((acc, currentValue) => {
            return acc + parseInt(currentValue);
        }, 0);

        const totalWins = totalGames - statsMap['-1'];
        const winPercentage = totalGames === 0 ? 0 : totalWins / totalGames * 100;
        return { winPercentage: winPercentage.toFixed(2), totalGames: totalGames, map: statsMap };
    }

    const handleClose = () => {
        props.setVisible(false);
    }

    const handleShare = () => {
        const statsObject = getStats();

        let statsText = `🎥 Check my https://www.filmconnect.fun statistics\n\n`;
        statsText += `🟢 Win Percentage: ${statsObject.winPercentage}%\n`;
        statsText += `🔛 Total Games: ${statsObject.totalGames}\n\n`;
        statsText += "📊 Missed Guesses Distribution:\n";

        const numberEmojis = ['0', '1', '2', '3', '4'];
        for (let i = 0; i <= 4; i++) {
            const key = numberEmojis[i];
            statsText += `${key}: ${statsObject.map[i]}\n`;
        }

        navigator.clipboard.writeText(statsText)
            .then(() => {
                toast.success('Copied to clipboard')
            })
            .catch(err => {
                toast.error('Could not generate stats. Try again later')
            });
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            TransitionComponent={Grow}
            TransitionProps={{ timeout: 800 }}
            fullWidth>

            <DialogTitle style={{
                textAlign: 'left',
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                borderBottom: '1px solid ' + theme.palette.secondary.main
            }}>
                <InlineIconTypography
                    icon={<LuBarChart fontSize={24} />}
                    typography={<Typography variant="h5">
                        My
                        <Typography variant="h5" display={'inline'} color={theme.palette.secondary.mainLight} marginLeft={theme.spacing(0.8)}>
                            Statistics
                        </Typography>
                    </Typography>}
                    spacing={1}
                />
            </DialogTitle>

            <DialogContent dividers style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
                <Grid container direction={'row'} spacing={isMobile(width) ? 1 : 1.5} paddingInline={0.5}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Success Rate</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<LuCheck fontSize={20} />}
                            typography={<InlineTypography
                                spacing={0.5}
                                typography={<Typography variant="body2" color={theme.palette.secondary.mainLight}> Total </Typography>}
                                typographyAppend={
                                    <InlineTypography
                                        spacing={0.5}
                                        typography={<Typography variant="body2" display="inline" style={{ backgroundColor: theme.palette.primary.contrastText, paddingInline: theme.spacing(0.3), borderRadius: theme.spacing(0.4), color: theme.palette.primary.main, fontWeight: 'bold' }}>
                                            {getStats().totalGames}
                                        </Typography>}
                                        typographyAppend={
                                            <InlineTypography
                                                spacing={0.5}
                                                typography={<Typography variant="body2" display="inline" color={theme.palette.secondary.mainLight}>
                                                    games on
                                                </Typography>}
                                                typographyAppend={
                                                    <InlineTypography
                                                        spacing={0.5}
                                                        typography={
                                                            <Typography variant="body2" display="inline" style={{ backgroundColor: theme.palette.primary.contrastText, paddingInline: theme.spacing(0.3), borderRadius: theme.spacing(0.4), color: theme.palette.primary.main, fontWeight: 'bold' }}>
                                                                {getStats().winPercentage}%
                                                            </Typography>
                                                        }
                                                        typographyAppend={
                                                            <Typography variant="body2" display="inline" color={theme.palette.secondary.mainLight}>
                                                                guess rate
                                                            </Typography>
                                                        }
                                                    />
                                                }
                                            />
                                        }
                                    />
                                }
                            />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Missed Guesses Distribution</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<PiNumberSquareZeroLight fontSize={20} color={theme.palette.secondary.mainLight} />}
                            typography={<LinearProgressBar currentCount={getStats().map[0]} totalCount={getStats().totalGames} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<PiNumberSquareOneLight fontSize={20} color={theme.palette.secondary.mainLight} />}
                            typography={<LinearProgressBar currentCount={getStats().map[1]} totalCount={getStats().totalGames} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<PiNumberSquareTwoLight fontSize={20} color={theme.palette.secondary.mainLight} />}
                            typography={<LinearProgressBar currentCount={getStats().map[2]} totalCount={getStats().totalGames} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<PiNumberSquareThreeLight fontSize={20} color={theme.palette.secondary.mainLight} />}
                            typography={<LinearProgressBar currentCount={getStats().map[3]} totalCount={getStats().totalGames} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<PiNumberSquareFourLight fontSize={20} color={theme.palette.secondary.mainLight} />}
                            typography={<LinearProgressBar currentCount={getStats().map[4]} totalCount={getStats().totalGames} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InlineIconTypography
                            icon={<PiXSquareLight fontSize={20} color={theme.palette.secondary.mainLight} />}
                            typography={<LinearProgressBar currentCount={getStats().map[-1]} totalCount={getStats().totalGames} />}
                        />
                    </Grid>
                    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                        <LighBorderAccentColoredButton variant="outlined" style={{ backgroundColor: theme.palette.secondary.main }} onClick={handleShare}>SHARE</LighBorderAccentColoredButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
};

export default StatsPopup;
