import axios from "axios";
import { BASE_PATH, DAILY_GAME_SLUG } from "../config/RestConfig";
import { ACKOWNLEDGE_FILLER_POPUP_KEY, ACKOWNLEDGE_MOVIE_INFO_POPUP_KEY, GAME_STATE_KEY, PUZZLE_INFO_KEY, STATS_KEY, retrieveFromStorage, saveToLocalStorage } from "../config/LocalStorageConfig";

//REST
export function getDailyGame(data) {
    return axios.get(BASE_PATH + DAILY_GAME_SLUG + '/active?timeZone=' + data)
}

export function getDailyGameStatistics(data) {
    return axios.get(BASE_PATH + DAILY_GAME_SLUG + '/active/stats?timeZone=' + data)

}

export function postDailyGameEntry(data) {
    return axios.post(BASE_PATH + DAILY_GAME_SLUG + '/entry', data);
}


//LOCAL STORAGE

export function getPuzzleInfo() {
    return retrieveFromStorage(PUZZLE_INFO_KEY);
}

export function savePuzzleInfo(data) {
    saveToLocalStorage(PUZZLE_INFO_KEY, data);
}

export function getGameState(data) {
    return retrieveFromStorage(GAME_STATE_KEY);
}

export function saveGameState(data) {
    saveToLocalStorage(GAME_STATE_KEY, data);
}

export function getStats() {
    return retrieveFromStorage(STATS_KEY);
}

export function saveStats(data) {
    saveToLocalStorage(STATS_KEY, data);
}

export function getAcknowledgeMovieInfoPopup() {
    return retrieveFromStorage(ACKOWNLEDGE_MOVIE_INFO_POPUP_KEY);
}

export function saveAcknowledgeMovieInfoPopup(data) {
    saveToLocalStorage(ACKOWNLEDGE_MOVIE_INFO_POPUP_KEY, data);
}

export function getAcknowledgeFillerPopup() {
    return retrieveFromStorage(ACKOWNLEDGE_FILLER_POPUP_KEY);
}

export function saveAcknowledgeFillerPopup(data) {
    saveToLocalStorage(ACKOWNLEDGE_FILLER_POPUP_KEY, data);
}