import React from "react";
import { Box, Grid, LinearProgress, Typography, useTheme } from "@mui/material";

const LinearProgressBar = (props) => {
    const { totalCount, currentCount } = props;
    const percentage = totalCount === 0 ? 0 : (currentCount / totalCount) * 100;
    const theme = useTheme();

    return (
        <Box display="flex" alignItems="center" width="70%">
            <Grid container direction={'row'} spacing={theme.spacing(0.5)} alignItems={'center'}>
                <Grid item xs={10}>
                    <Box width="100%" mr={1}>
                        <LinearProgress
                            variant="determinate"
                            value={percentage}
                            sx={{
                                backgroundColor: theme.palette.secondary.main,
                                height: '0.8dvh',
                                "& .MuiLinearProgress-bar": {
                                    backgroundColor: props.color || theme.palette.primary.contrastText,
                                },
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2" fontWeight={'bold'}>
                        {percentage.toFixed(0)}%
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default LinearProgressBar;
