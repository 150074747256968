export function flattenMovies(puzzle) {
    const movies = [];
    Object.values(puzzle.moviesConnections).forEach(difficulty => {
        difficulty.movies.forEach(movie => {
            movies.push(movie);
        });
    });
    return movies;
};

export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function findMatchingMovieSet(movieList, puzzleInfo) {
    const movieSets = Object.entries(puzzleInfo.moviesConnections);

    var maxComparisonCounter = 0;
    for (const [key, movieSet] of movieSets) { // iterate movie sets on puzzle
        if (key === 'FILLER') {
            continue;
        }
        const movies = movieSet.movies;

        var movieComparisonCounter = compareMovieSetsByName(movieList, movies); // check equal movies from set (returns int)
        if (movieComparisonCounter === 4) { // found a matching set
            return { difficulty: key, counter: movieComparisonCounter };
        }

        if (movieComparisonCounter > maxComparisonCounter) { // did not found a matching set - save if bigger than max (if 3 send a warning)
            maxComparisonCounter = movieComparisonCounter;
        }
    }
    return { difficulty: null, counter: maxComparisonCounter };
}

export function compareMovieSetsByName(movieSet1, movieSet2) {
    if (movieSet1.length !== movieSet2.length) {
        return false;
    }

    // Sort both sets of movies by name for accurate comparison (FIX ME: THIS MIGHT BREAK IF ORDER IS MESSED UP LOL)
    const sortedSet1 = movieSet1.slice().sort((a, b) => a.name.localeCompare(b.name));
    const sortedSet2 = movieSet2.slice().sort((a, b) => a.name.localeCompare(b.name));

    // Check if all movies in both sets are equal
    var similarityCounter = 0;
    for (let i = 0; i < sortedSet1.length; i++) {
        var value = sortedSet1[i];
        for (let j = 0; j < sortedSet2.length; j++) {
            var valueTwo = sortedSet2[j]
            if (value.name === valueTwo.name) {
                similarityCounter++;
            }
        }

    }
    return similarityCounter;
}

export function sortRowAnswerByDifficulty(rowAnswer) {
    const difficultyOrder = {
        "EASY": 1,
        "MEDIUM": 2,
        "HARD": 3,
        "FILLER": 4
    };

    const result = rowAnswer.sort((a, b) => {
        return difficultyOrder[a.difficulty] - difficultyOrder[b.difficulty];
    });

    return result;
}

export function sortMoviesByDifficulty(puzzleInfo) {
    console.log(puzzleInfo);
    const movieOrder = [];

    const difficultyLevels = ['EASY', 'MEDIUM', 'HARD', 'FILLER'];
    difficultyLevels.forEach(level => {
        if (puzzleInfo.moviesConnections != undefined) {
            const movies = puzzleInfo.moviesConnections[level].movies;
            movies.forEach(movie => {
                movieOrder.push(movie);
            });
        }
    });

    return movieOrder;

}