import { useTheme } from "@emotion/react";
import { PrimaryDarkBackgroundPaper } from "../styles/Paper";
import { Grid2, Stack } from "@mui/material";
import useWindowDimensions, { isMobile } from "../hook/WindowDimensions";
import TopBar from "../component/TopBar";
import TipsBlock from "../component/TipsBlock";
import DateHeader from "../component/DateHeader";
import MoviesBlock from "../component/MoviesBlock";


const MainPage = (props) => {
    const theme = useTheme();

    const { height, width } = useWindowDimensions();


    return (
        <PrimaryDarkBackgroundPaper style={{ minHeight: '100dvh', backgroundRepeat: 'repeat' }}>
            <Grid2 container direction={'row'} justifyContent={'center'} alignItems={'center'}>
                {/** !PADDING BLOCK! */}
                <Grid2 item
                    style={{
                        maxWidth: isMobile(width) ? '100%' : '40%',
                        width: '100%',
                    }}>

                    <Stack spacing={isMobile(width) ? theme.spacing(1) : theme.spacing(2)} justifyContent={'center'} alignItems={'center'}>
                        {/** TOP BAR */}
                        <TopBar />
                        {/** DATE HEADER */}
                        <DateHeader/>
                        {/** TIPS BLOCK */}
                        <TipsBlock />
                        {/** MOVIES BLOCK  */}
                        <MoviesBlock />
                    </Stack>
                </Grid2>
            </Grid2>
        </PrimaryDarkBackgroundPaper>
    )
}

export default MainPage;