export const PUZZLE_INFO_KEY = 'puzzle_info';
export const GAME_STATE_KEY = 'game_state';
export const STATS_KEY = 'stats';
export const ACKOWNLEDGE_FILLER_POPUP_KEY = 'acknowledgeFillerPopup';
export const ACKOWNLEDGE_MOVIE_INFO_POPUP_KEY = 'acknowledgeMovieInfoPopup';

export function saveToLocalStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
}

export function retrieveFromStorage(key) {
    return JSON.parse(localStorage.getItem(key));
}